<script setup lang="ts">
import VueMultiselect from 'vue-multiselect'
// Properties and events.
//
const emit = defineEmits(['update:modelValue'])
const props = defineProps<{
  options: any[]
  closeOnSelect: boolean
  placeholderText: string
  highlight?: boolean
  modelValue: any
}>()
// Main variables.
//
const options = toRef(props, 'options')
const highlight = toRef(props, 'highlight')
const closeOnSelect = toRef(props, 'closeOnSelect')
const placeholderText = toRef(props, 'placeholderText')
// Model.
//
const value = computed({
  get(): string | any {
    return props.modelValue
  },

  set(value: any | Ref<string>) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <div
    class="rk-multi-select"
    :class="{ 'rk-multi-select--highlight': highlight }"
  >
    <VueMultiselect
      v-model="value"
      label="name"
      :options="options"
      :multiple="true"
      :close-on-select="closeOnSelect"
      track-by="name"
      :placeholder="placeholderText"
    ></VueMultiselect>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
