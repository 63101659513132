<script setup lang="ts">
// Types.
//
import { TagOperation, type TagScope } from '~/apollo/types'
// Properties and events.
//
const emit = defineEmits(['process:suggest-tags', 'process:add-tag'])
const props = defineProps<{
  id?: string
  scope: TagScope
  placeholder: string
  isFilter?: boolean
  reset?: boolean
  supplied?: any
  highlight?: boolean
}>()
// Main variables.
//
const { Add } = TagOperation
const scope = toRef(props, 'scope')
const reset = toRef(props, 'reset')
const placeholder = toRef(props, 'placeholder')
const supplied = toRef(props, 'supplied')
const highlight = toRef(props, 'highlight')
const isFilter = toRef(props, 'isFilter')
const id: any = toRef(props, 'id')
const searchedTag = ref('')
const suggestedTags = ref<string[]>([])
const error = ref('')
const filter = ref('')
// Formats the tags to how the data is expected in the multiselect component (used in filters).
//
const options = computed((): any => {
  if (!isFilter) return
  return useTagStore()
    .suggestTags(scope.value, id.value, searchedTag.value)
    .map((tag) => {
      return { name: tag, val: tag }
    })
})
// Functions.
//
function suggestTags() {
  error.value = ''

  const foundTags = useTagStore().suggestTags(
    scope.value,
    id.value,
    searchedTag.value,
  )

  if (foundTags.length > 0) {
    suggestedTags.value = foundTags
  } else {
    suggestedTags.value = []
    error.value = 'No tags found'
  }
}

function emitAddTag(tag: any, operation?: TagOperation) {
  if (isFilter && Array.isArray(tag)) {
    const filteredTags = tag.map((t) => t.name) || []
    emit('process:add-tag', filteredTags)
  } else {
    emit('process:add-tag', tag, operation)
  }
  suggestedTags.value = []
  searchedTag.value = ''
}

function resetSuggestions() {
  // Handles clearing the suggested list on blur (added a timeout as without it, you can't add a tag).
  //
  setTimeout(() => {
    suggestedTags.value = []
    searchedTag.value = ''
    error.value = ''
  }, 200)
}

watch(reset, () => {
  if (reset.value) {
    filter.value = ''
  }
})

watch(supplied, () => {
  if (supplied.value && supplied.value.length > 0) {
    filter.value = supplied.value
  }
})
</script>

<template>
  <div class="rk-tag__suggested-input">
    <MultiSelection
      v-if="isFilter"
      v-model="filter"
      :options="options"
      :label="placeholder"
      :highlight="highlight || false"
      :close-on-select="false"
      :placeholder-text="placeholder"
      @update:modelValue="emitAddTag"
    />
    <TextInput
      v-else
      v-model="searchedTag"
      :label="placeholder"
      type="text"
      :removeSpacing="true"
      validateOnFocus
      @update:modelValue="suggestTags"
      @input:focus="suggestTags"
      @input:blur="resetSuggestions"
    />

    <div
      v-if="suggestedTags.length || error"
      class="rk-tag rk-tag__suggested-list"
    >
      <p
        v-for="(tag, index) in suggestedTags"
        :key="index"
        @click="emitAddTag(tag, Add)"
        class="rk-tag rk-tag__suggested-item"
      >
        {{ tag }}
      </p>
      <p v-if="error.length" class="rk-text p-2 m-0">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<style lang="scss"></style>
