<template>
  <div id="booking-list-view" @click="showCurrencies = false">
    <div class="heading row mb-3">
      <div class="col-12">
        <h1>Bookings</h1>
      </div>
    </div>
    <Bar>
      <BarItem name="filter">
        <Btn
          base-style="light"
          style-opts="rk-filter-btn rk-btn--text-icon me-3"
          @process:click="toggleFilters"
        >
          <i
            v-if="filtersPanel"
            class="rk-icon rk-icon--text-xs fontello icon-sys-close"
          ></i>
          <i
            v-else
            class="rk-icon rk-icon--text-xs fontello icon-sys-search"
          ></i>
          <span>Filters</span>
        </Btn>
      </BarItem>
      <BarItem name="action" style-opts="text-end">
        <Btn
          :disabled="
            !activeFilters.reference &&
            !activeFilters.description &&
            !activeFilters.customer.firstName &&
            !activeFilters.customer.lastName &&
            !activeFilters.customer.email &&
            !activeFilters.passengers.firstName &&
            !activeFilters.passengers.lastName &&
            !activeFilters.passengers.email &&
            activeFilters.tags.include.length === 0 &&
            currencyFilter.length === 6 &&
            !statusFilter
          "
          base-style="light"
          style-opts="hide-disable-sm"
          @process:click="resetFilters"
        >
          Reset Filters
        </Btn>
        <Btn
          :link="true"
          path="/bookings/create"
          base-style="secondary"
          style-opts="rk-btn--text-icon"
        >
          <i class="rk-icon rk-icon--text-sm fontello icon-sys-edit"></i>
          Create New
        </Btn>
      </BarItem>
    </Bar>
    <ExpandablePanel
      :panel-state="filtersPanel"
      panel-title="Filters"
      @process:close-panel="toggleFilters"
    >
      <div class="row g-3 mb-3">
        <div class="col-12 col-md-4">
          <TextInput
            v-model.trim="filters.reference"
            type="text"
            label="Reference"
            :delay="500"
            :highlight="filters.reference.length > 0"
            @update:modelValue="applyFilter($event, 'reference', 'CONTAINS')"
          />
        </div>
        <div class="col-12 col-md-4">
          <TextInput
            v-model.trim="filters.description"
            type="text"
            label="Description"
            :delay="500"
            :highlight="filters.description.length > 0"
            @update:modelValue="applyFilter($event, 'description', 'CONTAINS')"
          />
        </div>
        <div class="col-12 col-md-4" @click.stop>
          <div v-if="currencyFilter" class="dropdown">
            <Btn
              class="rk-btn--currency w-100"
              base-style="light"
              @process:click="showCurrencies = !showCurrencies"
            >
              Currency
            </Btn>
            <FiltersCurrency
              v-model="currencyFilter"
              :menu-state="showCurrencies"
            />
          </div>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
        <div class="col">
          <TextInput
            v-model.trim="filters.customer.firstName"
            type="text"
            label="Contact First Name"
            :delay="500"
            :highlight="filters.customer.firstName.length > 0"
            @update:modelValue="
              applyFilter($event, ['customer', 'firstName'], 'CONTAINS')
            "
          />
        </div>
        <div class="col">
          <TextInput
            v-model.trim="filters.customer.lastName"
            type="text"
            label="Contact Last Name"
            :delay="500"
            :highlight="filters.customer.lastName.length > 0"
            @update:modelValue="
              applyFilter($event, ['customer', 'lastName'], 'CONTAINS')
            "
          />
        </div>
        <div class="col">
          <TextInput
            v-model.trim="filters.customer.email"
            type="text"
            label="Contact Email"
            :delay="500"
            :highlight="filters.customer.email.length > 0"
            @update:modelValue="
              applyFilter($event, ['customer', 'email'], 'CONTAINS')
            "
          />
        </div>
        <div class="col">
          <TextInput
            v-model.trim="filters.passengers.firstName"
            type="text"
            label="Passenger First Name"
            :delay="500"
            :highlight="filters.passengers.firstName.length > 0"
            @update:modelValue="
              applyFilter($event, ['passengers', 'firstName'], 'CONTAINS')
            "
          />
        </div>
        <div class="col">
          <TextInput
            v-model.trim="filters.passengers.lastName"
            type="text"
            label="Passenger Last Name"
            :delay="500"
            :highlight="filters.passengers.lastName.length > 0"
            @update:modelValue="
              applyFilter($event, ['passengers', 'lastName'], 'CONTAINS')
            "
          />
        </div>
        <div class="col">
          <TextInput
            v-model.trim="filters.passengers.email"
            type="text"
            label="Passenger Email"
            :delay="500"
            :highlight="filters.passengers.email.length > 0"
            @update:modelValue="
              applyFilter($event, ['passengers', 'email'], 'CONTAINS')
            "
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <SuggestedTagInput
            placeholder="Filter by tag"
            isFilter
            :scope="scope"
            :reset="reset"
            :supplied="tagFilter"
            :highlight="activeFilters.tags.include.length > 0"
            @process:add-tag="applyFilter($event, ['tags', 'include'])"
          />
        </div>
        <div class="col-12 col-md-6">
          <MultiSelection
            v-model="statusFilter"
            :options="statusOptions"
            label="Status"
            :close-on-select="false"
            :highlight="statusFilter.length > 0"
            placeholder-text="Filter by status"
          />
        </div>
      </div>
    </ExpandablePanel>
    <div class="py-3">
      <div v-if="$apollo.queries.bookings.loading">
        <LoadingTable />
      </div>
      <div v-else>
        <BookingsTable
          :active-ids="activeIds"
          :list-type="listType"
          :page-num="pageNum"
          @process:count="itemCount = $event"
          @process:booking-id="$emit('process:booking-id', $event)"
        />
      </div>
      <div v-if="itemCount !== 0" class="text-end">
        <p>
          Showing: <b>{{ itemCount }}</b> of <b>{{ listTotal }}</b>
        </p>
      </div>
      <div
        v-if="
          (!hasFilters && itemCount >= perPage) ||
          (!hasFilters && pageNum === totalPages)
        "
      >
        <ListPagination
          v-if="totalPages > 1"
          v-model="pageNum"
          :pages="totalPages"
          style-opts="w-100"
          @update="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { entries, ceil } from 'lodash'
import { mixin } from '@/mixins/global'
import { TagScope } from '~/apollo/types'
// GQL queries.
//
import bookingsStatsGQL from '@/apollo/queries/bookingStats.graphql'

export default defineComponent({
  props: {
    activeIds: {
      type: Array as PropType<string[]>,
    },
    listType: {
      type: String,
    },
  },
  apollo: {
    bookings: {
      query: bookingsStatsGQL,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      cache: false,
      deep: true,
      variables(): any {
        return {
          organizationId: useAuthStore().user
            ? useAuthStore().user!.organization.id
            : useAuthStore().orgId,
        }
      },
      update(data: any) {
        this.listTotal = data ? data.bookings.stats.count : 0
        this.totalPages = ceil(this.listTotal / useBookingStore().perPage)
      },
      result({ data, loading }: { data: any; loading: boolean }) {
        if (!loading) {
          this.listTotal = data ? data.bookings.stats.count : 0
          this.totalPages = ceil(this.listTotal / useBookingStore().perPage)
        }
      },
    },
  },
  computed: {
    ...mapState(useBookingStore, ['hasFilters']),
    mixin() {
      return mixin
    },
    filtersPanel() {
      return useGlobalStore().filters
    },
    activeFilters() {
      return useBookingStore().filters
    },
    perPage() {
      return useBookingStore().perPage
    },
  },
  data() {
    const scope = TagScope.Bookings
    const statusOptions = [
      { name: 'Confirmed', val: 'CONFIRMED' },
      { name: 'Pending', val: 'PENDING' },
      { name: 'Cancelled', val: 'CANCELLED' },
      { name: 'On Hold', val: 'ON_HOLD' },
      { name: 'Error', val: 'ERROR' },
      { name: 'Archived', val: 'ARCHIVED' },
    ]
    const currencyFilter = ['GBP', 'USD', 'CAD', 'NZD', 'AUD', 'INR']
    const showCurrencies = false
    const bookings: any[] = []
    const statusFilter = ''
    const tagFilter = ''
    const reset = false
    const itemCount = 0
    const listTotal = 0
    const totalPages = 0
    const pageNum = 1
    const filters = {
      reference: '',
      description: '',
      customer: {
        firstName: '',
        lastName: '',
        email: '',
      },
      passengers: {
        firstName: '',
        lastName: '',
        email: '',
      },
    }

    return {
      showCurrencies,
      statusOptions,
      currencyFilter,
      statusFilter,
      tagFilter,
      reset,
      bookings,
      itemCount,
      listTotal,
      totalPages,
      filters,
      pageNum,
      scope,
    }
  },
  methods: {
    handlePageChange(page: number) {
      this.pageNum = page
      useBookingStore().offSet = (this.pageNum - 1) * useBookingStore().perPage
    },
    applyFilter(filter: string, target: string | string[], operation?: string) {
      this.pageNum = 1
      useBookingStore().offSet = 0
      if (filter && filter.length > 0 && typeof target === 'string') {
        useBookingStore().filters[target] = {
          operation: operation,
          value: filter,
        }
      } else if (filter && filter.length > 0 && Array.isArray(target)) {
        if (operation) {
          useBookingStore().filters[target[0]][target[1]] = {
            operation: operation,
            value: filter,
          }
        } else {
          useBookingStore().filters[target[0]][target[1]] = Array.isArray(
            filter,
          )
            ? [...filter]
            : filter
        }
      } else if (
        (!filter && typeof target === 'string') ||
        (filter && filter.length === 0 && typeof target === 'string')
      ) {
        const singleFilter: any = target
        useBookingStore().filters[singleFilter] = null
      } else if (
        (!filter && Array.isArray(target)) ||
        (filter && filter.length === 0 && Array.isArray(target))
      ) {
        useBookingStore().filters[target[0]][target[1]] = Array.isArray(filter)
          ? []
          : null
      }
    },
    resetFilters() {
      this.reset = true
      setTimeout(() => {
        this.reset = false
      }, 100)
      this.pageNum = 1
      useBookingStore().offSet = 0
      this.currencyFilter = ['GBP', 'USD', 'CAD', 'NZD', 'AUD', 'INR']
      this.statusFilter = ''
      this.tagFilter = ''
      this.filters = {
        reference: '',
        description: '',
        customer: {
          firstName: '',
          lastName: '',
          email: '',
        },
        passengers: {
          firstName: '',
          lastName: '',
          email: '',
        },
      }
      useBookingStore().filters = {
        reference: null,
        description: null,
        currency: {
          operation: 'INCLUDE',
          values: ['GBP', 'USD', 'CAD', 'NZD', 'AUD', 'INR'],
        },
        customer: {
          firstName: null,
          lastName: null,
          email: null,
        },
        passengers: {
          firstName: null,
          lastName: null,
          email: null,
        },
        status: {
          include: ['CANCELLED', 'CONFIRMED', 'PENDING', 'ON_HOLD', 'ERROR'],
        },
        tags: {
          match: 'ANY',
          include: [],
          exclude: [],
        },
      }
    },
    toggleFilters() {
      useGlobalStore().filters = !useGlobalStore().filters
    },
  },
  mounted() {
    this.pageNum = 1
    useBookingStore().offSet = 0
    this.currencyFilter = this.activeFilters.currency.values || [
      'GBP',
      'USD',
      'CAD',
      'NZD',
      'AUD',
      'INR',
    ]
    this.filters = {
      reference: this.activeFilters.reference
        ? this.activeFilters.reference.value
        : '',
      description: this.activeFilters.description
        ? this.activeFilters.description.value
        : '',
      customer: {
        firstName: this.activeFilters.customer.firstName
          ? this.activeFilters.customer.firstName.value
          : '',
        lastName: this.activeFilters.customer.lastName
          ? this.activeFilters.customer.lastName.value
          : '',
        email: this.activeFilters.customer.email
          ? this.activeFilters.customer.email.value
          : '',
      },
      passengers: {
        firstName: this.activeFilters.passengers.firstName
          ? this.activeFilters.passengers.firstName.value
          : '',
        lastName: this.activeFilters.passengers.lastName
          ? this.activeFilters.passengers.lastName.value
          : '',
        email: this.activeFilters.passengers.email
          ? this.activeFilters.passengers.email.value
          : '',
      },
    }
    // Reverse setting of status & tag filters.
    //
    if (
      this.activeFilters.status.include &&
      this.activeFilters.status.include.length < 5
    ) {
      const setBookingStatus: any = []
      entries(this.activeFilters.status.include).forEach((entry: any) => {
        const [, value] = entry
        const status = mixin.rkSetBookingStatus(value)
        setBookingStatus.push(status)
      })
      this.statusFilter = setBookingStatus
    }
    if (
      this.activeFilters.tags.include &&
      this.activeFilters.tags.include.length > 0
    ) {
      const setTags: any = []
      entries(this.activeFilters.tags.include).forEach((entry: any) => {
        const [, value] = entry
        const tag = mixin.rkSetTag(value, TagScope.Bookings)
        setTags.push(tag)
      })
      this.tagFilter = setTags
    }
  },
  watch: {
    currencyFilter: {
      handler(values) {
        if (values && values.length > 0) {
          useBookingStore().filters.currency.values = values
        } else {
          useBookingStore().filters.currency.values = [
            'GBP',
            'USD',
            'CAD',
            'NZD',
            'AUD',
            'INR',
          ]
        }
      },
    },
    statusFilter: {
      handler(values) {
        if (values && values.length > 0) {
          const status: string[] = []
          entries(values).forEach((entry: any) => {
            const [, value] = entry
            status.push(value.val)
          })
          useBookingStore().filters.status.include = status
        } else {
          useBookingStore().filters.status.include = [
            'CANCELLED',
            'CONFIRMED',
            'PENDING',
            'ON_HOLD',
            'ERROR',
          ]
        }
      },
    },
  },
})
</script>

<style lang="scss"></style>
