<template>
  <div v-if="$apollo.queries.bookings.loading">
    <LoadingTable />
  </div>
  <div v-else>
    <Table
      :table-hover="true"
      :table-sortable="true"
      :loading-state="$apollo.queries.bookings.loading"
      :result-count="bookingCount"
    >
      <TableHead :is-interactive="true">
        <TableHeadItem name="Status" />
        <TableHeadItem
          name="Reference"
          :is-sortable="true"
          sort-term="REFERENCE"
          :current-term="activeSortTerm"
          :current-direction="activeSortDirection"
          default-direction="ASC"
          @process:sort="sortTable"
        />
        <TableHeadItem
          name="Booking Name"
          :is-sortable="true"
          sort-term="DESCRIPTION"
          :current-term="activeSortTerm"
          :current-direction="activeSortDirection"
          default-direction="ASC"
          @process:sort="sortTable"
        />
        <TableHeadItem
          name="Price"
          :is-sortable="true"
          sort-term="TOTAL_PRICE"
          :current-term="activeSortTerm"
          :current-direction="activeSortDirection"
          default-direction="DESC"
          @process:sort="sortTable"
        />
        <TableHeadItem name="Booking Contact" />
        <TableHeadItem
          name="Date Created"
          :is-sortable="true"
          sort-term="CREATED_AT"
          :current-term="activeSortTerm"
          :current-direction="activeSortDirection"
          default-direction="DESC"
          @process:sort="sortTable"
        />
        <TableHeadItem name="Pax" />
        <TableHeadItem name="Start/End" />
        <TableHeadItem name="Balances" />
      </TableHead>
      <TableBody sticky-title="Reference">
        <BookingsTableBody
          :exclude-ids="activeIds"
          :bookings="bookings"
          :list-type="listType || null"
          @process:booking-id="$emit('process:booking-id', $event)"
        />
      </TableBody>
    </Table>
    <div v-if="hasFilters" class="py-3">
      <ListPager
        :limit="perPage"
        :offset="activeOffSet"
        :items="bookings"
        @process:prev-page="prevPage()"
        @process:next-page="nextPage()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { mixin } from '@/mixins/global'
// GQL queries.
//
import bookingSearchGQL from '@/apollo/queries/tables/bookings.graphql'

export default defineComponent({
  props: {
    pageNum: {
      type: Number,
    },
    activeIds: {
      type: Array as PropType<string[]>,
    },
    listType: {
      type: String,
    },
  },
  apollo: {
    bookings: {
      query: bookingSearchGQL,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      cache: false,
      deep: true,
      variables(): any {
        return {
          organizationId: useAuthStore().user
            ? useAuthStore().user!.organization.id
            : useAuthStore().orgId,
          sortBy: useBookingStore().sortBy,
          direction: useBookingStore().sortDirection,
          limit: useBookingStore().perPage,
          offset:
            this.route.query &&
            this.route.query.offset &&
            parseInt(this.route.query.offset) > 0
              ? parseInt(this.route.query.offset)
              : useBookingStore().offSet,
          filters: {
            reference: useBookingStore().filters.reference,
            description: useBookingStore().filters.description,
            customer: useBookingStore().filters.customer,
            passengers: useBookingStore().filters.passengers,
            status: useBookingStore().filters.status,
            currency: useBookingStore().filters.currency,
            tags: useBookingStore().filters.tags,
          },
        }
      },
      update(data: any) {
        this.bookings = data ? data.bookings.bookings : []
      },
      result({ data, loading }: { data: any; loading: boolean }) {
        if (!loading) {
          this.bookings = data ? data.bookings.bookings : []
        }
      },
    },
  },
  computed: {
    ...mapState(useBookingStore, ['hasFilters']),
    mixin() {
      return mixin
    },
    activeOffSet() {
      const offset: any =
        this.route.query && this.route.query.offset
          ? this.route.query.offset
          : '0'
      return this.route.query && this.route.query.offset
        ? parseFloat(offset)
        : useBookingStore().offSet
    },
    activeSortTerm() {
      return useBookingStore().sortBy
    },
    activeSortDirection() {
      return useBookingStore().sortDirection
    },
    bookingCount() {
      return this.bookings ? this.bookings.length : 0
    },
    perPage() {
      return useBookingStore().perPage
    },
  },
  setup() {
    const route = useRoute()

    return {
      route,
    }
  },
  data() {
    const bookings: any[] | any = []

    return {
      bookings,
    }
  },
  methods: {
    sortTable(data: any) {
      useBookingStore().sortBy = data.term
      useBookingStore().sortDirection = data.direction
    },
    prevPage() {
      const offset = this.activeOffSet - this.perPage

      useBookingStore().offSet = offset

      if (offset === 0) {
        // If we're returning to the first page, remove the offset param.
        //
        this.$router.replace({
          query: { ...this.$route.query, offset: undefined },
        })
      } else {
        this.$router.replace({
          query: { ...this.$route.query, offset: offset.toString(10) },
        })
      }
    },
    nextPage() {
      useBookingStore().offSet = this.activeOffSet + this.perPage

      this.$router.replace({
        query: {
          ...this.$route.query,
          offset: useBookingStore().offSet.toString(10),
        },
      })
    },
  },
  watch: {
    bookingCount: {
      handler(value) {
        this.$emit('process:count', value)
      },
    },
    hasFilters: {
      handler(value) {
        if (!value) {
          this.$router.replace({
            query: { ...this.$route.query, offset: undefined },
          })
        }
      },
    },
  },
})
</script>

<style lang="scss"></style>
