<script setup lang="ts">
import type { Ref } from 'vue'
// Properties and events.
//
const emit = defineEmits(['update:modelValue'])
const props = defineProps<{
  menuState: boolean
  modelValue: string[]
}>()
// Main variables.
//
// Model.
//
const value = computed({
  get(): string[] | any {
    return props.modelValue
  },

  set(value: string[] | Ref<string[]>) {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <ul class="dropdown-menu py-2 px-3" :class="{ show: menuState }">
    <li class="d-flex align-items-center mb-1">
      <div class="form-check me-2">
        <input
          id="currency-filter-GBP"
          v-model="value"
          class="form-check-input"
          type="checkbox"
          value="GBP"
        />
      </div>
      <label for="currency-filter-GBP">
        <span class="flag me-2 flag-GBP"></span> GBP
      </label>
    </li>
    <li class="d-flex align-items-center mb-1">
      <div class="form-check me-2">
        <input
          id="currency-filter-USD"
          v-model="value"
          class="form-check-input"
          type="checkbox"
          value="USD"
        />
      </div>
      <label for="currency-filter-USD">
        <span class="flag me-2 flag-USD"></span> USD
      </label>
    </li>
    <li class="d-flex align-items-center mb-1">
      <div class="form-check me-2">
        <input
          id="currency-filter-CAD"
          v-model="value"
          class="form-check-input"
          type="checkbox"
          value="CAD"
        />
      </div>
      <label for="currency-filter-CAD">
        <span class="flag me-2 flag-CAD"></span> CAD
      </label>
    </li>
    <li class="d-flex align-items-center mb-1">
      <div class="form-check me-2">
        <input
          id="currency-filter-NZD"
          v-model="value"
          class="form-check-input"
          type="checkbox"
          value="NZD"
        />
      </div>
      <label for="currency-filter-NZD">
        <span class="flag me-2 flag-NZD"></span> NZD
      </label>
    </li>
    <li class="d-flex align-items-center mb-1">
      <div class="form-check me-2">
        <input
          id="currency-filter-AUD"
          v-model="value"
          class="form-check-input"
          type="checkbox"
          value="AUD"
        />
      </div>
      <label for="currency-filter-AUD">
        <span class="flag me-2 flag-AUD"></span> AUD
      </label>
    </li>
    <li class="d-flex align-items-center mb-1">
      <div class="form-check me-2">
        <input
          id="currency-filter-INR"
          v-model="value"
          class="form-check-input"
          type="checkbox"
          value="INR"
        />
      </div>
      <label for="currency-filter-INR">
        <span class="flag me-2 flag-INR"></span> INR
      </label>
    </li>
  </ul>
</template>

<style lang="scss"></style>
